import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PDFJS from 'pdfjs-dist';
import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'; 
import { initFocusLayer } from '../focusLayer';
import debounce from '../helpers/debounce';
import Menu from './Menu';


class PDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 0,
      numPages: 0,
      zoom: 1,
    };
    this.changeZoom = this.changeZoom.bind(this);
  }

  componentDidMount() {
    const { pdfFile, clearPDF } = this.props;
    if (!pdfFile || !pdfFile.loader) {
      return;
    }

    this.setState({
      viewer: new PDFViewer({
        container: this.containerNode,
      }),
    });

    this.containerNode.addEventListener('pagechange', (event) => {
      const { viewer } = this.state;
      viewer.pageNumber = event.pageNumber;
      this.setState({ pageNumber: viewer.pageNumber });
    });

    this.containerNode.addEventListener('pagesinit', () => {
      document.focusreader = false;
      const { viewer } = this.state;
      viewer.pageNumber = 1;
      this.setState({
        pageNumber: viewer.pageNumber,
        numPages:
          viewer.pdfDocument && viewer.pdfDocument.numPages
            ? viewer.pdfDocument.numPages : 0,
      });
    });

    this.containerNode.addEventListener('textlayerrendered', () => {
      initFocusLayer();
    });


    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    PDFJS.getDocument(pdfFile.loader)
      .then((pdf) => {
        const { viewer } = this.state;
        viewer.setDocument(pdf);
      })
      .catch(() => {
        clearPDF();
        window.alert('This file type is not supported. Please contact oscar@useclark.com for more information.');
      });
  }

  changeZoom(multiplier) {
    const { viewer, zoom } = this.state;
    const zoomDifference = 0.1 * multiplier;
    const newZoom = Math.round((zoom + zoomDifference) * 100) / 100;
    if (newZoom < 0.1) {
      return;
    }
    viewer.currentScaleValue = 1 * newZoom;
    this.setState({
      zoom: newZoom,
    });
    document.querySelectorAll('[focusreader-generated]').forEach(($page) => {
      $page.removeAttribute('focusreader-generated');
    });
  }

  render() {
    const { clearPDF } = this.props;
    const {
      pageNumber,
      numPages,
      zoom,
    } = this.state;

    return (
      <div>
        <Menu
          clearPDF={clearPDF}
          pageNumber={pageNumber}
          numPages={numPages}
          zoom={zoom}
          changeZoom={this.changeZoom}
        />
        <div
          id="viewerContainer"
          className="absolute pin overflow-auto mt-10 bg-grey-darkest"
          onWheel={(e) => {
            if (e.ctrlKey === false) return;
            e.preventDefault();
            const multiplier = e.deltaY > 0 ? -1 : 1;
            debounce(() => {
              this.changeZoom(multiplier);
            }, 30)();
          }}
          ref={(el) => {
            this.containerNode = el;
          }}
        >
          <div className="pdfViewer" />
        </div>
      </div>
    );
  }
}

PDF.propTypes = {
  pdfFile: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  clearPDF: PropTypes.func.isRequired,
};

export default PDF;
