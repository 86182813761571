import React from 'react';
import Home from './Home';
import './style/App.css';
import 'pdfjs-dist/web/pdf_viewer.css';
import './style/pdf.css';

function App() {
  return (
    <Home />
  );
}

export default App;
