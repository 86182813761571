import { resetLines } from './lines';
import makeLines from './makeLines';
import { makeBar, bar } from './bar';
import { selectLine } from './selectLine';
import lockin from './lockin';
import arrowKeys from './arrowKeys';

export function setSpeed(speed) {
  bar.speed = speed;
}

export function initFocusLayer() {
  if (document.focusreader) {
    makeBar();
    makeLines();
    return;
  }

  document.focusreader = true;

  resetLines();
  makeBar();
  makeLines();
  selectLine({ pageNumber: 1, lineNumber: 1 });
  lockin();
  arrowKeys();
}
