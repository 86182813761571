import { lines } from './lines';

const difference = (a, b) => Math.abs(a - b);

function textNodesUnder(el) {
  const textNodes = [];
  const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
  while (walk.nextNode()) {
    textNodes.push(walk.currentNode);
  }
  return textNodes;
}

function compare(a, b) {
  if (a.top < b.top) {
    return -1;
  }
  if (a.top > b.top) {
    return 1;
  }
  // a must be equal to b
  return 0;
}


function makeLines() {
  const { perPage } = lines;
  const $pages = document.querySelectorAll('#viewerContainer .page[data-loaded="true"]:not([focusreader-generated])');
  $pages.forEach(($page) => {
    // Generate blocks
    const $blocks = $page.querySelectorAll('.textLayer div');
    if (!$blocks.length) {
      return;
    }
    $blocks.forEach(($block) => {
      if (!$block || !$block.innerText || !$block.innerText.length) {
        return;
      }
      if ($block.getElementsByTagName('focusreader-text-node').length) {
        return;
      }
      const wrapper = document.createElement('focusreader-text-node');
      const textNodes = textNodesUnder($block);
      textNodes.forEach((textNode) => {
        wrapper.appendChild(textNode);
      });
      $block.appendChild(wrapper);
    });

    $page.setAttribute('focusreader-generated', true);

    // Generate the lines
    let lastRect = 0;
    let lineCount = 1;
    let line = [];

    let countArea = 0;
    let prevAreaTop = 0;
    const areasObject = {};

    const pageNumber = $page.getAttribute('data-page-number');
    if (!perPage[pageNumber]) {
      perPage[pageNumber] = {};
    }

    // Loop the focusreader-text-node nodes and calculate lines.
    const $focusReaderTextNodes = $page.querySelectorAll('focusreader-text-node:not([focusreader-line-number])');
    $focusReaderTextNodes.forEach(($focusReaderTextNode) => {
      const thisRect = $focusReaderTextNode.getBoundingClientRect();

      // per line
      if (
        !lastRect
        || thisRect.left <= lastRect.left
        || difference(thisRect.top, lastRect.top) > $focusReaderTextNode.offsetHeight
      ) {
        if (line.length) {
          // Generate areas
          if ((prevAreaTop + 100) < lastRect.top) {
            countArea += 1;
          }
          if (prevAreaTop > lastRect.top) {
            countArea += 1;
          }
          if (!areasObject[countArea]) {
            areasObject[countArea] = {
              elements: [],
              top: lastRect.top,
            };
          }
          areasObject[countArea].elements.push(line);
          prevAreaTop = parseInt(lastRect.top, 10);

          // Next line
          lineCount += 1;
        }
        line = [];
        perPage[pageNumber][lineCount] = line;
      }


      lastRect = thisRect;
      line.push($focusReaderTextNode);
    });

    if (line.length) {
      if ((prevAreaTop + 100) < lastRect.top) {
        countArea += 1;
      }
      if (prevAreaTop > lastRect.top) {
        countArea += 1;
      }
      if (!areasObject[countArea]) {
        areasObject[countArea] = {
          elements: [],
          top: lastRect.top,
        };
      }
      areasObject[countArea].elements.push(line);
      perPage[pageNumber][lineCount] = line;
    }


    // end of page
    perPage[pageNumber] = Object
      .values(areasObject)
      .sort(compare)
      .map(area => (
        area.elements
      ))
      .flat();

    perPage[pageNumber].forEach((l, index) => {
      l.forEach((element) => {
        element.setAttribute('focusreader-line-number', index);
      });
    });
  });
}

export default makeLines;
