import React, { Component } from 'react';

class Manual extends Component {

  constructor(props) {
    super(props);
    this.onManualModalClick = this.onManualModalClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onManualModalClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onManualModalClick, false);
  }

  onManualModalClick(e) {
    const { closeManual } = this.props;

    if (!this.manualModalNode) {
      return;
    }

    if (!this.manualModalNode.contains(e.target)) {
      closeManual();
    }
  }

  render() {
    return (
      <div
        className="fixed pin flex justify-center z-50 overflow-auto"
        style={{ background: 'rgba(63,72,83,0.6)' }}
      >
        <div className="w-full m-5">
          <div
            className="lg:w-1/2 my-16 mx-auto shadow-lg text-grey-darkest rounded p-10"
            style={{ background: '#F7F6FA' }}
            ref={(el) => {
              this.manualModalNode = el;
            }}
          >
            <div>
              <h1>Introduction</h1>
              <p className="mt-1">
                De Focus Reader Is a
                <strong> PDF reader </strong>
                with a
                <strong> focus mode</strong>
                . The focus mode allows you to guide your eyes which improves your concentration.
              </p>
              <ul className="mt-2">
                <li>
                  Needs a mouse to work and a browser that supports
                  {' '}
                  <a
                    className="text-grey-darkest"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://caniuse.com/#feat=pointerlock"
                  >
                    Pointer Lock
                  </a>
                </li>
                <li>
                  Does not work on tablets or mobile phones
                </li>
              </ul>
            </div>

            <div className="embed-container mt-5">
              <iframe
                title="introduction movie"
                src="https://player.vimeo.com/video/193552145?background=1"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullscreen
              />
            </div>

            <div className="mt-10">
              <h1>Tutorial</h1>
              
              <h2 className="mt-10">Basics</h2>
              
              <h4 className="mt-2">Open a PDF</h4>
              <p>You open a PDF by pressing the Open a PDF button on the homepage.</p>
              
              <h4 className="mt-2">Navigate pages</h4>
              <ol>
                <li>You can find the navigation button on the top menu.</li>
                <li>Use can also use the scrollwheel to navigate between pages.</li>
              </ol>

              <h4 className="mt-2">Zoom in/out</h4>
              <ol>
                <li>Use the zoom buttons in the top menu</li>
                <li>CTRL + scrollwheel While you press the CTRL button, scrolling upwards is zooming in, scrolling downwards is zooming out.</li>
              </ol>
            </div>


            <div className="mt-10">
              <h2>Turn Focus Mode On/Off</h2>
              
              <h4 className="mt-2">Start Focus Mode</h4>
              <p>Click on any word within the text and the focus mode will start automatically.</p>
              
              <h4 className="mt-2">Stop the Focus Mode</h4>
              <p>Click anywhere while in focus mode to stop the focus mode.</p>
            </div>

            <div className="mt-10">
              <h2>Using the Focus Mode</h2>
              <h4 className="mt-2">With the mouse or trackpad</h4>
              <ol>
                <li>Move the mouse gently from left to right, untill the end of the line. You are now guiding your eyes.</li>
                <li>When you move the mouse from right to left, when you have reached the end. The next line will be selected.</li>
                <li>Make sure you move the mouse left enough, so you have enough space to move to the right again.</li>
                <li>After a few tries you will find a rhytm for this.</li>
                <li>If you feel the mouse is moving to fast or slow, you can change the sensitivity in the top menu.</li>
              </ol>
              
              <h4 className="mt-2"> Using the arrow keys</h4>
              <p>You can use the arrow keys UP/DOWN to select a line higher or lower.</p>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Manual;
