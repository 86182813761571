export const bar = {
  lineEnded: false,
  position: 0,
  element: null,
  background: null,
  dimmer: {},
  speed: 1,
};

export function makeBar() {
  if (document.getElementById('focusreader-bar')) {
    return;
  }

  bar.speed = 1;

  bar.element = document.createElement('div');
  bar.element.id = 'focusreader-bar';

  bar.background = document.createElement('div');
  bar.background.id = 'focusreader-bar-background';

  bar.adjust = document.createElement('div');
  bar.adjust.id = 'focusreader-bar-adjust';
  bar.adjust.classList.add('hidden');

  bar.dimmer = {
    top: document.createElement('div'),
    right: document.createElement('div'),
    bottom: document.createElement('div'),
    left: document.createElement('div'),
  };
  bar.dimmer.top.id = 'focusreader-bar-dimmer-top';
  bar.dimmer.right.id = 'focusreader-bar-dimmer-right';
  bar.dimmer.bottom.id = 'focusreader-bar-dimmer-bottom';
  bar.dimmer.left.id = 'focusreader-bar-dimmer-left';

  document.getElementById('viewerContainer').appendChild(bar.dimmer.top);
  document.getElementById('viewerContainer').appendChild(bar.dimmer.right);
  document.getElementById('viewerContainer').appendChild(bar.dimmer.bottom);
  document.getElementById('viewerContainer').appendChild(bar.dimmer.left);

  document.getElementById('viewerContainer').appendChild(bar.background);
  document.getElementById('viewerContainer').appendChild(bar.adjust);
  document.getElementById('viewerContainer').appendChild(bar.element);
}
