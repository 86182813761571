import {
  selectNext,
  selectPrevious,
  adjustHeight,
  adjustWidth,
  adjustTop,
  adjustLeft,
} from './selectLine';
import { bar } from './bar';


let timeOutAdjust;

function hideAdjust() {
  bar.adjust.classList.add('hidden');
}

function showAdjust() {
  window.clearTimeout(timeOutAdjust);
  timeOutAdjust = window.setTimeout(hideAdjust, 2000);
  bar.adjust.classList.remove('hidden');
}

function handleKeyDown(e) {
  // CTRL + SHIFT + up = dikker;
  // CTRL + SHIFT + down = dunner;
  // CTRL + SHIFT + left = smaller;
  // CTRL + SHIFT + right = breder;

  if (e.which === 38 && e.ctrlKey && e.shiftKey) { // up
    e.preventDefault();
    adjustHeight(1);
    showAdjust();
    return;
  }

  if (e.which === 40 && e.ctrlKey && e.shiftKey) { // down
    e.preventDefault();
    adjustHeight(-1);
    showAdjust();
    return;
  }

  if (e.which === 37 && e.ctrlKey && e.shiftKey) { // left
    e.preventDefault();
    adjustWidth(-1);
    showAdjust();
    return;
  }

  if (e.which === 39 && e.ctrlKey && e.shiftKey) { // right
    e.preventDefault();
    adjustWidth(1);
    showAdjust();
    return;
  }

  // CTRL + up = position up;
  // CTRL + down = position down;
  // CTRL + left = position left;
  // CTRL + right = position right;

  if (e.which === 38 && e.ctrlKey) { // up
    e.preventDefault();
    adjustTop(-1);
    showAdjust();
    return;
  }

  if (e.which === 40 && e.ctrlKey) { // down
    e.preventDefault();
    adjustTop(1);
    showAdjust();
    return;
  }

  if (e.which === 37 && e.ctrlKey) { // left
    e.preventDefault();
    adjustLeft(-1);
    showAdjust();
    return;
  }

  if (e.which === 39 && e.ctrlKey) { // right
    e.preventDefault();
    adjustLeft(1);
    showAdjust();
    return;
  }


  // key up
  if (
    !!document.querySelector('.focusreader-lockedin')
    && e.which === 38
  ) {
    e.preventDefault();
    selectPrevious();
  }

  // key down
  if (
    !!document.querySelector('.focusreader-lockedin')
    && e.which === 40
  ) {
    e.preventDefault();
    selectNext();
  }
}


let timeOut;
bar.hidden = true;

function hideBar() {
  if (bar.element) {
    bar.hidden = true;
    bar.element.classList.add('hidden');
  }
}

function handleMouseMove() {
  window.clearTimeout(timeOut);
  timeOut = window.setTimeout(hideBar, 2000);
  if (bar.hidden && bar.element) {
    bar.hidden = false;
    bar.element.classList.remove('hidden');
  }
}

export default function arrowKeys() {
  document.removeEventListener('keydown', handleKeyDown);
  document.addEventListener('keydown', handleKeyDown);
  document.removeEventListener('mousemove', handleMouseMove);
  document.addEventListener('mousemove', handleMouseMove);
}
