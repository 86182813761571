import { lines } from './lines';
import { bar } from './bar';
import { selectNext } from './selectLine';

export default function moveBar(movement) {
  // set the left position based on mousemove ment (newleft)
  const multiplier = bar.speed / 4;
  const newPosition = bar.position + (movement * multiplier);

  // if lineEnded && movement < bar.position (new line!)
  if (bar.lineEnded && (newPosition < bar.position)) {
    bar.lineEnded = false;
    selectNext();
    return;
  }

  // Is bar at the end of the line?
  if (
    newPosition > bar.position &&
    (
      newPosition + bar.element.offsetWidth
      >= (lines.currentLine.rect.right / 100) * 95
    )
  ) {
    bar.lineEnded = true;
  }

  // Bar cannot be a lower left than current line
  if (newPosition <= lines.currentLine.rect.left) {
    bar.element.style.left = `${lines.currentLine.rect.left}px`;
    return;
  }

  // Bar cannot be a higher right than current line, including the width of the bar
  if (
    (newPosition + bar.element.offsetWidth) >= lines.currentLine.rect.right
  ) {
    bar.position = lines.currentLine.rect.right - bar.element.offsetWidth;
    bar.element.style.left = `${lines.currentLine.rect.right - bar.element.offsetWidth}px`;
    return;
  }


  bar.position = newPosition;
  bar.element.style.left = `${bar.position}px`;
}
