import React, { Component } from 'react';
import InstallChromeImage from './installchrome.png';

class InstallInstructions extends Component {

  constructor(props) {
    super(props);
    this.onManualModalClick = this.onManualModalClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onManualModalClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onManualModalClick, false);
  }

  onManualModalClick(e) {
    const { closeInstallApp } = this.props;

    if (!this.manualModalNode) {
      return;
    }

    if (!this.manualModalNode.contains(e.target)) {
      closeInstallApp();
    }
  }

  render() {
    return (
      <div
        className="fixed pin flex justify-center z-50 overflow-auto"
        style={{ background: 'rgba(63,72,83,0.6)' }}
      >
        <div className="w-full m-5">
          <div
            className="lg:w-1/2 my-16 mx-auto shadow-lg text-grey-darkest rounded p-10"
            style={{ background: '#F7F6FA' }}
            ref={(el) => {
              this.manualModalNode = el;
            }}
          >
            <div>
              <div className="mb-5">
                <h2 className="mb-5 font-bold">Install the Focus Reader via the Chrome browser</h2>
                <div className="">
                  <div class="flex my-2 items-center">1. On your computer, open Chrome.</div>
                  <div class="flex my-2 items-center">2. Go to focusreader.com.</div>
                  <div class="flex my-2 items-center">3. At the top right of the address bar, click Install <img className="ml-1 inline-block" src={InstallChromeImage} alt="add button"/>.</div>
                  <div class="flex my-2 items-center">4. Follow the onscreen instructions to install the app.</div>
                </div>
                <div className="mt-5 text-sm">
                  For more information on installing a PWA: <a href="https://support.google.com/chrome/answer/9658361" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/9658361</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstallInstructions;
