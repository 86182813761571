import { selectLine } from './selectLine';
import moveBar from './moveBar';
import { lines } from './lines';

function isLocked(element) {
  return (
    document.pointerLockElement === element
    || document.mozPointerLockElement === element
  );
}

function lockOut() {
  if (document.mozExitPointerLock) {
    return document.mozExitPointerLock();
  }
  return document.exitPointerLock();
}

function lockin() {
  if (!document.body) {
    return;
  }

  const mouseMove = (e) => {
    const movementX = e.movementX || e.mozMovementX || 0;
    const { width } = lines.currentLine.rect;
    moveBar(movementX * (width / 200));
  };


  const changeCallback = () => {
    if (isLocked(document.body)) {
      document.addEventListener('mousemove', mouseMove, false);
      document.body.classList.add('focusreader-lockedin');
      // $('body').addClass('lockedin');
      // $('#highlighter').addClass('on');
      // $('#highlighter_bg').addClass('on');
      // $('.dim').addClass('on');
      // startDate = new Date();
      // wordsRead.count = 0;
    } else {
      document.removeEventListener('mousemove', mouseMove, false);
      document.body.classList.remove('focusreader-lockedin');
      // $('body').removeClass('lockedin');
      // $('#highlighter').removeClass('on');
      // $('#highlighter_bg').removeClass('on');
      // $('.dim').removeClass('on');
    }
  };


  document.addEventListener('click', (e) => {
    if (isLocked(document.body)) {
      lockOut();
      return;
    }

    if (
      !e.target
      || !e.target.nodeName
      || e.target.nodeName !== 'FOCUSREADER-TEXT-NODE'
      || window.getSelection().toString()
      || !e.target.getAttribute('focusreader-line-number')
      || !e.target.closest('.page').getAttribute('data-page-number')
    ) {
      return;
    }

    selectLine({
      pageNumber: e.target.closest('.page').getAttribute('data-page-number'),
      lineNumber: e.target.getAttribute('focusreader-line-number'),
    });

    // highlight.highlighter.lineEnded = false;
    // highlight.setHiglight(window, $(e.target).position().left);

    // reset changeCallback
    document.removeEventListener('pointerlockchange', changeCallback);
    document.removeEventListener('mozpointerlockchange', changeCallback);
    document.removeEventListener('webkitpointerlockchange', changeCallback);
    document.addEventListener('pointerlockchange', changeCallback);
    document.addEventListener('mozpointerlockchange', changeCallback);
    document.addEventListener('webkitpointerlockchange', changeCallback);

    document.body.requestPointerLock = document.body.requestPointerLock
      || document.body.mozRequestPointerLock
      || document.body.webkitRequestPointerLock;
    document.body.requestPointerLock();
  });
}

export default lockin;
