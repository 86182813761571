import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGraduationCap,
  faRoad,
  faFileImport,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import PDF from '../PDF';
import ManualModal from './ManualModal';
import LogoSmall from '../Logo_small.png';
import BigMockup from '../Big_mockup_clean_1200.png';
import InstallInstructions from './InstallAppModal';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: null,
      manual: null,
    };
    this.onOpenFile = this.onOpenFile.bind(this);
    this.clearPDF = this.clearPDF.bind(this);
    this.openManual = this.openManual.bind(this);
    this.closeManual = this.closeManual.bind(this);

    this.openInstallApp = this.openInstallApp.bind(this);
    this.closeInstallApp = this.closeInstallApp.bind(this);


    this.beforeinstallpromptCallback = this.beforeinstallpromptCallback.bind(this);
    this.installPrompt = this.installPrompt.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeinstallprompt', this.beforeinstallpromptCallback);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.beforeinstallpromptCallback);
  }

  onOpenFile(e) {
    const ref = e.target;
    const { files } = ref;
    if (!files || files.length === 0) { return; }
    Object.keys(files).forEach((key) => {
      const file = files[key];
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        this.setState({
          pdf: {
            loader: new Uint8Array(loadEvent.target.result),
            blob: file,
            name: file.name,
          },
        });
      };
      reader.readAsArrayBuffer(file);
    });
  }

  installPrompt() {
    const { deferredPrompt } = this.state;
    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
    });
    this.setState({ deferredPrompt: null });
  }

  beforeinstallpromptCallback(e) {
    e.preventDefault();
    this.setState({ deferredPrompt: e });
  }


  clearPDF() {
    this.setState({
      pdf: null,
    });
  }

  openManual() {
    this.setState({ manual: true });
  }

  closeManual() {
    this.setState({ manual: null });
  }

  openInstallApp() {
    this.setState({ installApp: true });
  }

  closeInstallApp() {
    this.setState({ installApp: null });
  }

  render() {
    const { pdf, manual, installApp } = this.state;

    if (pdf) {
      return (
        <PDF pdfFile={pdf} clearPDF={this.clearPDF} />
      );
    }

    // check support
    const requestPointerLock = document.body.requestPointerLock
      || document.body.mozRequestPointerLock
      || document.body.webkitRequestPointerLock
      || null;

    const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);


    return (
      <div>
        {
          !!manual
          && <ManualModal closeManual={this.closeManual} />
        }
        {
          !!installApp
          && <InstallInstructions closeInstallApp={this.closeInstallApp} />
        }
        <div className="fixed pin-t z-20 p-2 h-10 bg-black w-full flex shadow text-grey-lighter">
          <div className="p-0 text-sm">
            <img src={LogoSmall} alt="Logo-small" border="0" />
          </div>
          {
            !!requestPointerLock
            && !isMobile
            && (
              <>
                <button
                  type="button"
                  className="ml-auto bg-grey-lighter hover:bg-grey-light text-grey-darker font-bold py-1 px-2 rounded text-sm focus:outline-none"
                  onClick={(e) => {
                    e.preventDefault();
                    this.inputNode.click();
                  }}
                >
                  Open a PDF
                </button>
                <input
                  type="file"
                  className="hidden"
                  ref={(el) => {
                    this.inputNode = el;
                  }}
                  onChange={this.onOpenFile}
                />
              </>
            )
          }
        </div>

        <div className="flex min-h-screen text-grey-lighter bg-grey-darkest">
          <div className="flex-1 flex text-center flex-col justify-center">
            <div className="max-w-sm mx-auto p-5 mt-10 lg:p-0 lg:mt-0">
              <div className="mt-5 rounded-lg p-5 lg:p-10 bg-indigo">
                <h2>Guide your eyes, read faster</h2>
                <div>
                  {
                    !!requestPointerLock
                    && !isMobile
                    && (
                      <div>
                        <button
                          type="button"
                          className="mt-10 w-full block py-5 px-10 bg-white rounded-lg text-grey-darkest font-bold hover:bg-grey-light focus:outline-none"
                          onClick={(e) => {
                            e.preventDefault();
                            this.inputNode.click();
                          }}
                        >
                          Open a PDF file
                          <FontAwesomeIcon icon={faFileImport} className="ml-2" />
                        </button>
                        <input
                          type="file"
                          className="hidden"
                          ref={(el) => {
                            this.inputNode = el;
                          }}
                          onChange={this.onOpenFile}
                        />
                      </div>
                    )
                  }
                  {
                    (!requestPointerLock || !!isMobile)
                    && (
                      <div
                        className="mt-10 p-3 text-left text-grey-darkest bg-white border border-grey-light border-l-4"
                        style={{ borderLeftColor: 'orange' }}
                      >
                        <p className="font-bold">Oh no! Your browser does not seem to be supported.</p>
                        <p className="mt-4">The Focus Reader uses the mouse (Pointer Lock) for the guiding method.</p>
                        <p className="mt-2">
                          Please run the website on a desktop with a mouse and Pointer Lock available.
                        </p>
                        <p className="mt-2 break-words">
                          Check
                          {' '}
                          <a
                            style={{ wordBreak: 'break-all' }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://caniuse.com/#feat=pointerlock"
                          >
                            https://caniuse.com/#feat=pointerlock
                          </a>
                          {' '}
                          for supported browsers.
                        </p>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="mt-3">
                <button
                  type="button"
                  onClick={this.openManual}
                  className="bg-indigo hover:bg-indigo-dark w-full block py-4 px-4 rounded-lg text-grey-lighter text-grey-darkest font-bold focus:outline-none"
                >
                  Open introduction / tutorial
                  <FontAwesomeIcon icon={faGraduationCap} className="ml-2 fa-fw" />
                </button>
              </div>
              <div className="mt-3 flex">
                <a
                  href="https://trello.com/b/qjSxoF4c/focus-reader-roadmap"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-1 no-underline hover:bg-grey block p-2 bg-grey-light rounded-lg text-grey-darkest font-bold focus:outline-none"
                >
                  Roadmap
                  <FontAwesomeIcon icon={faRoad} className="ml-2 fa-fw" />
                </a>
                <button
                  type="button"
                  onClick={this.openInstallApp}
                  className="ml-3 flex-1 no-underline hover:bg-grey block p-2 bg-grey-light rounded-lg text-grey-darkest font-bold focus:outline-none"
                >
                  Install app
                  <FontAwesomeIcon icon={faDownload} className="ml-2 fa-fw" />
                </button>
              </div>
              <div className="mt-5 text-sm leading-normal text-grey-dark text-left">
                <p>
                  Powered by
                  {' '}
                  <a className="text-grey-dark" target="_blank" rel="noopener noreferrer" href="https://focusacademy.com">focusacademy.com</a>
                </p>
                <p className="mt-2">
                  For questions or bug reports please email:
                  {' '}
                  <a className="text-grey-dark" href="mailto:oscar@focusacademy.com">oscar@focusacademy.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1 hidden md:flex">
            <img className="self-center" src={BigMockup} alt="FocusReader Mockup" />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
