import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faArrowLeft,
  faArrowRight,
  faSearchMinus,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { setSpeed } from '../../focusLayer';


function setPage(number) {
  const pageNodes = document.querySelectorAll(`[data-page-number="${number}"]`);
  if (!pageNodes || !pageNodes[0]) {
    return;
  }
  const pageNode = pageNodes[0];
  pageNode.scrollIntoView();
}

const SENSITIVITY_VALUES = [1, 2, 4, 8, 16];

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 1,
    };
    this.onChangeSpeed = this.onChangeSpeed.bind(this);
  }

  onChangeSpeed(event) {
    const speed = event.target.value;
    this.setState({ speed });
    setSpeed(speed);
  }

  render() {
    const {
      clearPDF,
      pageNumber,
      numPages,
      zoom,
      changeZoom,
    } = this.props;

    const { speed } = this.state;

    return (
      <div className="fixed pin-t z-20 p-2 h-10 bg-black w-full flex shadow">

        {/* PAGES */}
        <button
          type="button"
          className="bg-grey-lighter hover:bg-grey-light text-grey-darker font-bold py-1 px-2 rounded focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            setPage(pageNumber - 1);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="px-2 py-1 text-grey-light font-bold">
          { pageNumber }
          {' / '}
          { numPages }
        </div>
        <button
          type="button"
          className="bg-grey-lighter hover:bg-grey-light text-grey-darker font-bold py-1 px-2 rounded focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            setPage(pageNumber + 1);
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>

        {/* ZOOM */}
        <button
          type="button"
          className="ml-5 bg-grey-lighter hover:bg-grey-light text-grey-darker font-bold py-1 px-2 rounded focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            changeZoom(-1);
          }}
        >
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>
        <div className="px-2 py-1 text-grey-light font-bold">
          { Math.round(zoom * 100) }
          {'%'}
        </div>
        <button
          type="button"
          className="bg-grey-lighter hover:bg-grey-light text-grey-darker font-bold py-1 px-2 rounded focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            changeZoom(1);
          }}
        >
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>


        {/* SPEED */}
        <div className="ml-5 pr-2 py-1 text-grey-light font-bold">
          Sensitivity
        </div>
        <select
          value={speed}
          onChange={this.onChangeSpeed}
          className="text-center block appearance-none bg-grey-lighter rounded pl-2 pr-2 focus:outline-none text-grey-darker font-bold"
        >
          {
            SENSITIVITY_VALUES.map(value => (
              <option value={value} key={value}>
                {`${value}x`}
              </option>
            ))
          }
        </select>


        {/* EXIT */}
        <button
          type="button"
          className="ml-auto bg-grey-lighter hover:bg-grey-light text-grey-darker font-bold py-1 px-2 rounded focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            clearPDF();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    );
  }
}

Menu.propTypes = {
  clearPDF: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  changeZoom: PropTypes.func.isRequired,
};

export default Menu;
