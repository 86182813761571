import { lines } from './lines';
import { bar } from './bar';

let extraHeight = 0;
let extraWidth = 0;
let extraTop = 0;
let extraLeft = 0;


// Helper to calculate real top position
function offsetUntil(element, className) {
  let offsetTotal = 0;
  let el = element;
  while ((el = el.parentElement)) {
    offsetTotal += el.offsetTop;
    if (el.className === className) {
      break;
    }
  }
  return offsetTotal;
}

export function selectLine({ pageNumber, lineNumber }) {
  // check if pageNumber and lineNumberExist
  if (
    !lines
    || !lines.perPage
    || !lines.perPage[pageNumber]
    || !lines.perPage[pageNumber][lineNumber]
  ) {
    return;
  }

  lines.currentLine = {
    blocks: lines.perPage[pageNumber][lineNumber],
    pageNumber,
    lineNumber,
  };

  const allRects = [];
  lines.currentLine.blocks.forEach((block) => {
    Array.prototype.push.apply(allRects, block.getClientRects());
  });


  lines.currentLine.rect = {
    top: null,
    right: null,
    bottom: null,
    left: null,
    height: null,
    width: null,
  };

  // top
  lines.currentLine.rect.top = Math.min.apply(null, allRects.map(a => a.top));

  // right
  lines.currentLine.rect.right = Math.max.apply(null, allRects.map(a => a.right));

  // bottom
  lines.currentLine.rect.bottom = Math.max.apply(null, allRects.map(a => a.bottom));

  // left
  lines.currentLine.rect.left = Math.min.apply(null, allRects.map(a => a.left));

  // height
  lines.currentLine.rect.height = lines.currentLine.rect.bottom - lines.currentLine.rect.top;

  // width
  lines.currentLine.rect.width = lines.currentLine.rect.right - lines.currentLine.rect.left;

  // Extra margins.
  // lines.currentLine.rect.left -= 3;
  // lines.currentLine.rect.right += 3;
  // lines.currentLine.rect.width += 3 * 2;

  // Werkt het als we gewoon de currentLine rect aanpassen?
  lines.currentLine.rect.height += extraHeight; // dikker/dunner werkt
  lines.currentLine.rect.width += extraWidth; // breder/smaller werkt
  lines.currentLine.rect.left += extraLeft; // links/rechts werkt

  // Set bar
  if (!bar.element) {
    return;
  }

  bar.lineEnded = false;
  // top
  const offsetTop = offsetUntil(lines.currentLine.blocks[0], 'page') + extraTop; // hoger/lager werkt hier.
  bar.element.style.top = `${offsetTop + lines.currentLine.rect.height}px`;
  // left
  bar.element.style.left = `${lines.currentLine.rect.left}px`;
  bar.position = lines.currentLine.rect.left;
  // width
  lines.currentLine.highlightWidth = (17 / 2) * parseInt(lines.currentLine.rect.height, 10);
  if (lines.currentLine.highlightWidth > lines.currentLine.rect.width / 2) {
    lines.currentLine.highlightWidth = lines.currentLine.rect.width / 2;
  }
  bar.element.style.width = `${lines.currentLine.highlightWidth}px`;

  // background
  bar.background.style.width = `${lines.currentLine.rect.width}px`;
  bar.background.style.top = `${offsetTop + lines.currentLine.rect.height}px`;
  bar.background.style.left = `${lines.currentLine.rect.left}px`;
  
  // adjust
  bar.adjust.style.width = `${lines.currentLine.rect.width}px`;
  bar.adjust.style.top = `${offsetTop}px`;
  bar.adjust.style.left = `${lines.currentLine.rect.left}px`;
  bar.adjust.style.height = `${lines.currentLine.rect.height + 2}px`;

  // dimmer
  bar.dimmer.top.style.height = `${offsetTop}px`;
  bar.dimmer.top.style.width = '100%';
  bar.dimmer.top.style.top = 0;
  bar.dimmer.top.style.left = 0;
  bar.dimmer.right.style.height = `${lines.currentLine.rect.height + 2}px`;
  bar.dimmer.right.style.width = `calc(100% - ${lines.currentLine.rect.left + lines.currentLine.rect.width}px)`;
  bar.dimmer.right.style.top = `${offsetTop}px`;
  bar.dimmer.right.style.left = `${lines.currentLine.rect.left + lines.currentLine.rect.width}px`;
  bar.dimmer.bottom.style.height = `${bar.dimmer.bottom.parentElement.scrollHeight - (offsetTop + lines.currentLine.rect.height + 2)}px`;
  bar.dimmer.bottom.style.width = '100%';
  bar.dimmer.bottom.style.top = `${offsetTop + lines.currentLine.rect.height + 2}px`;
  bar.dimmer.bottom.style.left = 0;
  bar.dimmer.bottom.style.bottom = 0;
  bar.dimmer.left.style.height = `${lines.currentLine.rect.height + 2}px`;
  bar.dimmer.left.style.width = `${lines.currentLine.rect.left}px`;
  bar.dimmer.left.style.top = `${offsetTop}px`;
  bar.dimmer.left.style.left = 0;

  // Animate position if needed
  if (lines.currentLine.rect.bottom + 20 > window.innerHeight) {
    document.getElementById('viewerContainer').scrollTo({
      top: offsetTop - 20,
      behavior: 'smooth',
    });
  }
  if (offsetTop < document.getElementById('viewerContainer').scrollTop) {
    document.getElementById('viewerContainer').scrollTo({
      top: offsetTop - 20,
      behavior: 'smooth',
    });
  }
}

export function selectNext() {
  if (
    !lines.currentLine
    || !(lines.currentLine.pageNumber >= 0)
    || !(lines.currentLine.lineNumber >= 0)
  ) {
    return false;
  }

  const currentPageNumber = parseInt(lines.currentLine.pageNumber, 10);
  const currentLineNumber = parseInt(lines.currentLine.lineNumber, 10);

  // if same pageNumber and +1 lineNumber
  if (
    lines
    && lines.perPage
    && lines.perPage[currentPageNumber]
    && lines.perPage[currentPageNumber][currentLineNumber + 1]
  ) {
    selectLine({
      pageNumber: currentPageNumber,
      lineNumber: currentLineNumber + 1,
    });
    return true;
  }

  // if +1 pageNumber and lineNumber = 1.
  if (
    lines
    && lines.perPage
    && lines.perPage[currentPageNumber + 1]
    && lines.perPage[currentPageNumber + 1][0]
  ) {
    selectLine({
      pageNumber: currentPageNumber + 1,
      lineNumber: 0,
    });
    return true;
  }

  // else stop focusLayer.
  return false;
}

export function selectPrevious() {
  if (
    !lines.currentLine
    || !(lines.currentLine.pageNumber >= 0)
    || !(lines.currentLine.lineNumber >= 0)
  ) {
    return false;
  }

  const currentPageNumber = parseInt(lines.currentLine.pageNumber, 10);
  const currentLineNumber = parseInt(lines.currentLine.lineNumber, 10);

  // if same pageNumber and -1 lineNumber
  if (
    lines
    && lines.perPage
    && lines.perPage[currentPageNumber]
    && lines.perPage[currentPageNumber][currentLineNumber - 1]
  ) {
    selectLine({
      pageNumber: currentPageNumber,
      lineNumber: currentLineNumber - 1,
    });
    return true;
  }

  // if -1 pageNumber and lineNumber = last.
  if (
    lines
    && lines.perPage
    && lines.perPage[currentPageNumber - 1]
    && Object.keys(lines.perPage[currentPageNumber - 1])
    && Object.keys(lines.perPage[currentPageNumber - 1]).length - 1
  ) {
    selectLine({
      pageNumber: currentPageNumber - 1,
      lineNumber: Object.keys(lines.perPage[currentPageNumber - 1]).length - 1,
    });
    return true;
  }

  // else stop focusLayer.
  return false;
}

export function adjustHeight(extra) {
  extraHeight += extra;
  selectLine({
    pageNumber: lines.currentLine.pageNumber,
    lineNumber: lines.currentLine.lineNumber,
  });
}

export function adjustWidth(extra) {
  extraWidth += extra;
  selectLine({
    pageNumber: lines.currentLine.pageNumber,
    lineNumber: lines.currentLine.lineNumber,
  });
}

export function adjustTop(extra) {
  extraTop += extra;
  selectLine({
    pageNumber: lines.currentLine.pageNumber,
    lineNumber: lines.currentLine.lineNumber,
  });
}

export function adjustLeft(extra) {
  extraLeft += extra;
  selectLine({
    pageNumber: lines.currentLine.pageNumber,
    lineNumber: lines.currentLine.lineNumber,
  });
}
